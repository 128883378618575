import React from 'react';

const WindowsIcon = () => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{verticalAlign: "middle" }}>
      <circle cx="27" cy="27" r="27" fill="#E3D9CF"/>
        <rect x="10.8" y="10.8" width="32.4" height="32.4" fill="url(#pattern4)"/>
        <defs>
        <pattern id="pattern4" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_265_43" transform="scale(0.0104167)"/>
        </pattern>
        <image id="image0_265_43" width="96" height="96" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADyklEQVR4nO2du2sVQRTGP1EJiNj5QCFJoRjTGBCxEOyFFBaKYnyAaQRBLCzUxkch4s2jESS+QC0sFAKBaBQE/4OABjWiYqEpLggxiYK54JGFubB32Nm7e+/cOXN3zg9Ok3l8Z853Z3Y3xS4gCIIgCIIgCILQPDsAjACYAbAEgNo8lgC8AzAMoMfnH8hqAKMAKh4UjVoUFWXEKnhY/BceFIgcxXPfTBj1oCjkOKKd4M2ZX+RjhwwRrXk7PCDEXz+pGIIHzHhQCGKK6O6InUUPCkFMsQAPqJek6LUYMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZMYAZCjzYocCDHQo82KHAgx0KPNihwIMdCjzYocCDHdcJUsH1clP0gpAYUIsYEFhBSHZALWJAYAUh2QG1iAGBFYRkB9QiBgRWEJIdUIsYEFhBKMQdsALALod6aQRpQDeAyw710gjSgH4Arx3qpRGkARcA/AHQ4UgvjSANeKzG7nWk1wtgk6EtSAOm1diLjvROADhlaAvOgJXq+InGTjnQq750atzQFpwBPbGxiwkvx2tFQd4A+A1gjYP1WaeRBPsAdBnaDmrjd7dYL3rmmFdj+y2tzymNJBht+ZOGtiva+POW9KJzPoltsbFjltbnlLwJRu8YLQN4YJjvqTZ+wpLefYPe4djYObUjmtFzTt4ED6i/fzHM90EbP68uzM3qfTbo3WjBkeeUvAmOx9q6tLYOwztI+yzpdSa0v9TGX2tyfc7Jk+B6AMuxtuNa+07DHGct6Q0k5F/Wxk83sT4W8iR4Tmu7p7UPGOZ4ZknvjtbeaZiju0E9FvIkWH3CJcO5fN0wRzl2cWxGb9ZwfdDjTIPrYyFrgn2G9s5Yn4mUeXot6W2O9blq6DNVRANM75g+FuvzNWWe05b0jmQw/C+AdTn12MiSYPVePKn9ruqzFsC/lHmeWNK7Hcv9e8o8h3LosZIlQdNZSwA+qT576swzZ0nvveqzoc48j3LosZIlwfi9eFJsATCYYa6tFvSiXbYRwP468/xU/whsewP0e/GkOKo+/FNvrkFLetHxcinDXPuKYIB+L54UYwBeZej30JLerYT/OSVFqQgG6PfiSTEL4EeGft8s6c2oZ5B6/T62gwELGZIsavyCB4T8CZO38IBhDwpBTHETHtAT6Gesln35jFWou6AEj4geWCY9KAo5iknfPmUIldBwwY+jivrle1d8/ZowpD5yVoRvjC2qtZR8OvMFQRAEQRAEQUDb8h/sRXZTDDk7egAAAABJRU5ErkJggg=="/>
        </defs>
    </svg>
  );
};

export default WindowsIcon;